export class JournalEntry {
    key : number = 0;
    title : string = '';
    date : string = '';
    body : string[] = [];
    gps? : string;
    gpsLink? : string;
    imgName? : string;
}

export class JournalEntryCollection {
    private static entries : JournalEntry[] = [
        {
            key: 6303484, // yellow O
            title: 'A new project',
            date: '1994-07-23',
            body: [
                "So I've been contracted to work on this new project - a sequel to Tetris! I've decided to post these journal entries to catalog the journey.", 
                "This will be the third game in the Tetris series. I'm excited to be working on such a widely-known property! I love the original, and from the high-level talks I've been hearing from management, it sounds like there are some really interesting design choices.", 
                "It sounds like they're planning on using real-world objects in association with the game, like a sensor that will react when game pieces are nearby! Can't wait to get started."
            ],
            gps: '38.617834, -121.255569', // GPS to red Z
            gpsLink: 'https://www.google.com/maps/place/38%C2%B037\'04.2%22N+121%C2%B015\'20.1%22W/@38.6178546,-121.2556695,46m/data=!3m1!1e3!4m5!3m4!1s0x0:0x7b6c96d1e21326af!8m2!3d38.617834!4d-121.255569',
            imgName: '02_red_Z.jpg'
        },
        {
            key: 5491386, // red Z
            title: 'Work begins',
            date: '1994-08-17',
            body: [
                "It's been an interesting couple of weeks. While I've mostly been focusing on the title screen, there hasn't been a lot of communication regarding these new “peripherals”, as they're calling them. I did hear that the “sensor” has some strange behavior (glitch?) where it reacts more strongly to objects adjacent to the pieces, instead of the pieces itself. Weird! Oh well, back to the grind..."
            ],
            gps: '38.635312, -121.265394', // GPS to orange L
            gpsLink: 'https://www.google.com/maps/place/38%C2%B038\'07.1%22N+121%C2%B015\'55.4%22W/@38.6353791,-121.2657063,158m/data=!3m1!1e3!4m5!3m4!1s0x0:0x9d61b3afd948c9f5!8m2!3d38.635312!4d-121.265394',
            imgName: '03_orange_L.jpg'
        },
        {
            key: 7274928, // orange L
            title: 'More progress',
            date: '1994-09-09',
            body: [
                "I've finally begun work on the sophisticated logic for the game. This is going to be way more advanced than the original! I'm specifically focusing on the 1V1 logic. I want the computer to be unbeatable on harder difficulties! This has been fun, though admittedly something feels a bit off with the continued lack of communication. I'll check back in later."
            ],
            gps: '38.632152, -121.274268', // GPS to green S
            gpsLink: 'https://www.google.com/maps/place/38%C2%B037\'55.8%22N+121%C2%B016\'27.4%22W/@38.6325142,-121.2746222,281m/data=!3m1!1e3!4m5!3m4!1s0x0:0xa38bbf9ff89f94ac!8m2!3d38.632152!4d-121.274268',
            imgName: '04_green_S.jpg'
        },
        {
            key: 5224000, // green S
            title: 'Something feels off',
            date: '1994-09-23',
            body: [
                "Progress is steady, but I have to admit... I'm starting to feel a little paranoid. I can't explain it, but something has been feeling really weird lately, and there have been some unexplained happenings around the workplace. I can't really elaborate now, but I'm going to do some digging and post again later."
            ],
            gps: '38.631507, -121.281871', // GPS to blue J
            gpsLink: 'https://www.google.com/maps/place/38%C2%B037\'53.4%22N+121%C2%B016\'54.7%22W/@38.6319796,-121.2825711,341m/data=!3m1!1e3!4m5!3m4!1s0x0:0x81c743140fdadff0!8m2!3d38.631507!4d-121.281871',
            imgName: '05_blue_J.jpg'
        },
        {
            key: 4896217, // blue J
            title: 'What\'s going on?',
            date: '1994-10-01',
            body: [
                "I...think someone may be stalking me. At the very least, someone has definitely been messing with my stuff. I come in to work and my computer is all out of sorts, I've been receiving cryptic messages... I feel like something may be terribly wrong. Maybe I should work someplace else because this is giving me a real bad feeling."
            ],
            gps: '38.637079, -121.253573', // GPS to purple T
            gpsLink: 'https://www.google.com/maps/place/38%C2%B038\'13.5%22N+121%C2%B015\'12.9%22W/@38.6371267,-121.2553107,413m/data=!3m1!1e3!4m5!3m4!1s0x0:0x99283196afe2c3e4!8m2!3d38.637079!4d-121.253573',
            imgName: '06_purple_T.jpg'
        },
        {
            key: 8027580, // purple T
            title: 'I\'m out',
            date: '1994-10-12',
            body: [
                "OK, I've had enough – I'm definitely scared now. I'm keeping this short just to keep a log in case anybody reads this, but I've decided to quit this job and move on to something that feels less threatening. I feel bad giving up so early, but I have this overwhelming sense that someone is coming after me, and I"
            ],
            gps: '38.635567, -121.265579', // GPS to blue I
            gpsLink: 'https://www.google.com/maps/place/38%C2%B038\'08.0%22N+121%C2%B015\'56.1%22W/@38.6355739,-121.2657767,102m/data=!3m1!1e3!4m5!3m4!1s0x0:0x9f1d223d6d42cb35!8m2!3d38.635567!4d-121.265579',
            imgName: '07_blue_I.jpg'
        },
        {
            key: 9999999, // blue I (obtained from game; piece reads 'no data' on board)
            title: 'The truth',
            date: '1994-10-19',
            body: [
                "Sorry my last message was cut short – something interrupted my connection right as I was posting. And I know what it was.",
                "This is going to sound wild, but I may have accidentally invented some form of infectious AI that is capable of transmitting itself to other systems and taking control of them. My lights have been switching on and off, my TV is acting wildly, and yesterday my doorbell rang by itself only for me to discover a note that I don't think was written by a person.",
                "I'm going to do my best to seal what's left of this AI inside the board peripheral, and bury all the pieces. As long as the pieces aren't all connected, this AI shouldn't be able to escape. I'm throwing away my dev copy of Tetris 3.",
                "Come to think of it, Tetris 2 was pretty terrible, so why would they even make a third one? I'm moving back to Japan shortly anyway. This crazy series of events has got me thinking about another game I could make in the future involving babies in jars that can see ghosts and rain that makes you age. Wouldn't be any crazier than this.",
                "If anyone reads this, do not go looking for the pieces, and, more specifically, DO NOT complete the board peripheral puzzle. This AI needs to stay where it is, lest it spreads any more than it already has.",
                "Signing off."
            ]
        },
    ];

    static searchEntries = (searchTerm : string) => {
        if (searchTerm == '*') {
            return this.entries;
        }
        
        return this.entries.filter(x => x.key.toString() == searchTerm);
    }
}