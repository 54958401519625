import { useState, ChangeEvent, useEffect } from 'react';
import './App.css';
import { JournalEntry, JournalEntryCollection } from './models/JournalEntry';
import { useCookies } from 'react-cookie'

function App() {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchedTerm, setSearchedTerm] = useState<string>('');
  const [detailMode, setDetailMode] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<JournalEntry | undefined>(undefined);
  const [matchingEntries, setMatchingEntries] = useState<JournalEntry[]>([]);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [cookies, setCookie] = useCookies(['search_term'])

  const handleSearchTermChange = (event : ChangeEvent<HTMLInputElement> ) => {
    setSearchTerm(event.target.value);
  }

  const handleSearch = () => {
    setSearchedTerm(searchTerm.trim());
    setMatchingEntries(JournalEntryCollection.searchEntries(searchTerm.trim()));
    setCookie('search_term', searchTerm.trim());
  }

  const handleEntryClick = (key : number) => {
    setSelectedEntry(matchingEntries.find(x => x.key === key));
    setShowImage(false);
    setDetailMode(true);
  }

  const handleBackClick = () => {
    setSelectedEntry(undefined);
    setDetailMode(false);
  }

  useEffect(() => {
    if (!searchTerm) {
      const cachedSearchTerm = cookies.search_term;
      if (cachedSearchTerm) {
        setSearchTerm(cachedSearchTerm);
        setMatchingEntries(JournalEntryCollection.searchEntries(cachedSearchTerm));
      }
    }
  }, []);

  const renderSearchMode = () => {
    return (
      <>
        <div>
          <h1>hpbdb archive</h1>
        </div>
        <div>
          <input value={searchTerm} onChange={handleSearchTermChange}></input><button onClick={handleSearch}>Search</button>
        </div>
        <div>
          {!!matchingEntries.length &&
            <div>
              <h2>Matching results:</h2>
              {matchingEntries.map(x => (
                <div key={x.key}>#{x.key} <a href="#" onClick={() => handleEntryClick(x.key)}>{x.date} : {x.title}</a></div>
              ))}
            </div>
          }
          {!matchingEntries.length && searchedTerm &&
            <>
              <br></br>
              <div>No results.</div>
            </>
          }
        </div>
      </>
    )
  }
  const renderDetailMode = () => {
    if (!selectedEntry) {
      return <></>;
    }

    const showAttachments = selectedEntry.gps || selectedEntry.imgName;
    const imgUrl = '/assets/images/' + selectedEntry.imgName;
    const imgLinkText = showImage ? 'Hide' : 'Show';

    return (
      <>
        <div>
          #{selectedEntry.key}
          <br></br><br></br>
          <a href="#" onClick={handleBackClick}>&lt;&lt; Back</a>
          <br></br><br></br>
        </div>
        <div>
          {selectedEntry.date}: {selectedEntry.title}
        </div>
        {selectedEntry.body.map(x => {
          return <p>{x}</p>
        })
        }
        {showAttachments &&
        <div>
          <div>
            <u>Attachments:</u>
          </div>
          <div>
            GPS data: ({selectedEntry.gps}) <a href={selectedEntry.gpsLink} target='_blank'>[Link]</a>
          </div>
          <div>
            Image: [<a href="#" onClick={() => setShowImage(!showImage)}>{imgLinkText}</a>]
            {showImage &&
              <>
                <br></br>
                <a href={imgUrl} target="_blank"><img src={imgUrl} width="300"></img></a>
              </>
            }
          </div>
        </div>
        }
        <div>
          <br></br>
          <a href="#" onClick={handleBackClick}>&lt;&lt; Back</a>
        </div>
      </>
    )
  }

  return (
    <div className="App">
      <header className="App-header">
        {detailMode ? renderDetailMode() : renderSearchMode()}
      </header>
    </div>
  );
}

export default App;